(function () {
    angular.module('informaApp')
        .component('logout', {
            controller: LogoutController,
            templateUrl: 'components/logout/template.html'
        });

    function LogoutController(AuthenticationService, NavigatorService) {
        if (AuthenticationService.isLoggedIn()) {
            NavigatorService.openLogoutRedirect();
        }
    }
})();
